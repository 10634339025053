import React, {useCallback, useState, useEffect} from 'react'
import LeftMenu from "../../components/left-menu/LeftMenu";
import {exportAPI, windfarmAPI} from "../../api";
import SimpleDefectView from "../../components/simple-defect-view/TotalDefectOverview";
import './main-page.css'
import Card from "../../components/card/Card";
import DefectPie from "../../components/pie-chart/DefectPie";
import PivotTable from "../../components/pivot-table/PivotTable";
import {generatePath, Link, useNavigate, useParams} from 'react-router-dom';
import DocumentCard from "../../components/document-card/DocumentCard";
import BladeDefectTable from "../../components/blade-defect-table/BladeDefectTable";
import WplButton from "../../components/wpl-button/WplButton";
import {
    getDefectsFlow,
    getTurbineDefectsFlow,
    getWindfarmDefectsFlow,
    selectAllDefects,
    selectDefectsLoading
} from "../../flow/defectFLOW";
import WplMultiSelect from "../../components/wpl-multi-select/wpl-multi-select";
import WplPopup from "../../components/wpl-popup-window/WplPopup";

import support from '../../assets/img/support.svg'
import loading from '../../assets/loading.svg'
import waving from '../../assets/img/emoji_people-24px.svg'
import check from "../../assets/img/done-24px.svg";
import mailIcon from "../../assets/img/mail-24px.svg";
import phoneIcon from "../../assets/img/call-24px.svg";
import WplTabs from "../../components/wpl-tabs/WplTabs";
import {useDispatch, useSelector} from "react-redux";

export default function MainPage() {
    const [severityCounts, setSeverityCounts] = useState({});
    const [windfarms, setWindfarms] = useState([]);

    const [inspectionDateDefects, setInspectionDateDefects] = useState([]);

    const [inspectionDates, setInspectionDates] = useState([]);
    const [selectedInspectionDate, setSelectedInspectionDate] = useState('');

    const [exportImages, setExportImages] = useState(true);
    const [exportRepairScope, setExportRepairScope] = useState(['Not repaired', 'Selected for repair', 'Repaired'])
    const [loadingExport, setLoadingExport] = useState(false);
    const [exportUrl, setExportUrl] = useState('');
    const [isLongExport, setIsLongExport] = useState(false);

    const [defectsNotRepaired, setDefectsNotRepaired] = useState([]);
    const [defectsSelectedForRepair, setDefectsSelectedForRepair] = useState([]);
    const [defectsRepaired, setDefectsRepaired] = useState([]);

    let {windfarm_id: initial_windfarm_id, turbine_id: initial_turbine_id} = useParams();
    initial_windfarm_id = initial_windfarm_id && parseInt(initial_windfarm_id);
    initial_turbine_id = initial_turbine_id && parseInt(initial_turbine_id);

    const [selectedWindfarm, setSelectedWindfarm] = useState('');
    const [selectedTurbine, setSelectedTurbine] = useState('');

    const [filterOnLocation, setFilterOnLocation] = useState('All')

    const dispatch = useDispatch();
    const navigate = useNavigate();

    const defects = useSelector(selectAllDefects)
    const defectsLoading = useSelector(selectDefectsLoading)

    const location_filter = useCallback(ds => {
        let res = [...ds]
        if (filterOnLocation === 'Internal') {
            res = res.filter(d => d.is_internal)
        } else if (filterOnLocation === 'External') {
            res = res.filter(d => !d.is_internal)
        }
        return res
    }, [filterOnLocation]);

    useEffect(() => {
        windfarmAPI.getWindfarms().then(wfs => setWindfarms(wfs.sort((a, b) => a.name.localeCompare(b.name))))
    }, [])

    useEffect(() => {
        if (windfarms.length === 0) return;

        if (!initial_windfarm_id) {
            setSelectedWindfarm('');
            setSelectedTurbine('');
            return
        }

        if (!initial_turbine_id) {
            setSelectedTurbine('');
        }

        let wf = windfarms.find(wf => wf.id === initial_windfarm_id);
        if (!wf) return;

        setSelectedWindfarm({
            ...wf,
            turbines: wf.turbines.sort((a, b) => a.name.localeCompare(b.name))
        })
        const selectedT = wf.turbines.find(turbine => turbine.id === initial_turbine_id)
        if (!selectedT) return;

        setSelectedTurbine(selectedT)
    }, [windfarms, initial_windfarm_id, initial_turbine_id]);

    useEffect(() => {
        if (initial_turbine_id) {
            dispatch(getTurbineDefectsFlow(initial_turbine_id));
        } else if (initial_windfarm_id) {
            dispatch(getWindfarmDefectsFlow(initial_windfarm_id));
        } else {
            dispatch(getDefectsFlow())
        }
    }, [dispatch, initial_turbine_id, initial_windfarm_id]);

    useEffect(() => {
        const inspectionDates = defects.map(d => d.inspectionDate)
        const uniqueDates = inspectionDates
            .filter((t, idx) => inspectionDates.indexOf(t) === idx)
            .sort((a, b) => (new Date(a)) - (new Date(b)))

        setInspectionDates([...uniqueDates, 'Newest turbine data']);
    }, [defects]);

    useEffect(() => {
        setSelectedInspectionDate('Newest turbine data');
        setFilterOnLocation('All')
    }, [selectedTurbine, selectedWindfarm]);

    useEffect(() => {
        if (!selectedWindfarm) {
            setInspectionDateDefects(location_filter(defects))
            return;
        }

        if (selectedInspectionDate !== 'Newest turbine data') {
            setInspectionDateDefects(location_filter(defects.filter(d => d.inspectionDate === selectedInspectionDate)))
            return;
        }
        const turbineInspections = {};
        defects.forEach(defect => {
            if (defect.rdspp in turbineInspections) {
                if (new Date(defect.inspectionDate) < new Date(turbineInspections[defect.rdspp])) {
                    return
                }
            }
            turbineInspections[defect.rdspp] = defect.inspectionDate
        })
        setInspectionDateDefects(location_filter(defects.filter(d => d.inspectionDate === turbineInspections[d.rdspp])))

    }, [selectedWindfarm, defects, selectedInspectionDate, filterOnLocation, location_filter]);

    useEffect(() => {
        setDefectsNotRepaired(inspectionDateDefects.filter(d => d.markedForRepair === null && d.repaired === null));
        setDefectsSelectedForRepair(inspectionDateDefects.filter(d => d.markedForRepair !== null && d.repaired === null));
        setDefectsRepaired(inspectionDateDefects.filter(d => d.repaired !== null))
    }, [inspectionDateDefects]);

    useEffect(() => {
        const scs = {1: 0, 2: 0, 3: 0, 4: 0, 5: 0};
        inspectionDateDefects.forEach(d => {
            scs[d.severity] += 1
        });
        setSeverityCounts(scs)
    }, [inspectionDateDefects]);

    const startExport = useCallback(() => {
        let ds = []
        if (exportRepairScope.indexOf('Not repaired') !== -1) {
            ds = [...ds, ...defectsNotRepaired]
        }
        if (exportRepairScope.indexOf('Selected for repair') !== -1) {
            ds = [...ds, ...defectsSelectedForRepair]
        }
        if (exportRepairScope.indexOf('Repaired') !== -1) {
            ds = [...ds, ...defectsRepaired]
        }
        if (ds.length <= 10) {
            setLoadingExport(true);
        } else {
            setIsLongExport(true);
        }
        exportAPI.startExport(ds.map(d => d.id), !exportImages).then(url => {
            setExportUrl(url)
            setLoadingExport(false);
            setIsLongExport(false);
        }).catch(({response}) => {
            setLoadingExport(false);
            setIsLongExport(false);
            alert(`${response.data}`);
        });
    }, [exportRepairScope, defectsNotRepaired, defectsSelectedForRepair, defectsRepaired, setLoadingExport, setIsLongExport, exportImages]);

    const selectedRepairScope = (item) => {
        if (exportRepairScope.indexOf(item) === -1) {
            setExportRepairScope([...exportRepairScope, item])
        } else {
            setExportRepairScope(exportRepairScope.filter(k => k !== item))
        }
    }

    if (!defectsLoading && windfarms.length === 0) {
        return <div className='empty-wrapper'>
            <Link style={{color: 'black', fontSize: '2em', marginBottom: '16px'}} to={'/lassie'}>Click here if you are looking for Lassie</Link>
            <div className='empty-content'>
                <div className='hello-wrap'>
                    <img src={waving} alt='man waving'/>
                    <h2>Hello! It looks like you are missing some windfarms...</h2>
                </div>
                <div className='hello-wrap'>
                    <h2>...you should talk to our engineers and we will get it sorted in no time.</h2>
                    <img src={support} alt='support'/>
                </div>
            </div>

            <div className='ctas'>
                <a href='mailto:support@windpowerlab.com' className='call-to-action-btn'>
                    <img src={mailIcon} alt='support-email' className='icon-img'/>
                    <span>support@windpowerlab.com</span>
                </a>
                <a href='tel:004531321006' className='call-to-action-btn'>
                    <img src={phoneIcon} alt='blade-phone' className='icon-img'/>
                    <span>+45 31 321 006</span>
                </a>
            </div>
        </div>
    }

    return (<div className='main-wrapper'>
        <LeftMenu selectedWindfarm={selectedWindfarm}
                  selectedTurbineId={initial_turbine_id}
                  windfarms={windfarms}
        />
        <div className='main-content'>
            <div className='breadcrumb'>
                <div>
                    <h2>{initial_turbine_id ?  'Turbine' : (selectedWindfarm ? 'Windfarm' : 'Portfolio')}</h2>
                    <span>{selectedTurbine ? `${selectedWindfarm.name} - ${selectedTurbine.name} `: (selectedWindfarm ? selectedWindfarm.name : 'All windfarms')}</span>
                </div>
                {selectedWindfarm && <div className='inspection-date-wrapper'>
                    <h3>Inspection Date</h3>
                    <WplMultiSelect
                        title='Inspection date'
                        singleSelect={true}
                        selected={selectedInspectionDate}
                        onSelected={setSelectedInspectionDate}
                        options={inspectionDates}
                    />
                </div>}
            </div>

            {defects.some(d => d.is_internal) && defects.some(d => !d.is_internal) &&
                <Card className='internal-external-card'>
                    <h3>There are both internal and external defects. Would you like to filter?</h3>
                    <WplTabs tabTitles={['All', 'External', 'Internal']} selected={filterOnLocation}
                             setSelected={setFilterOnLocation}/>
                </Card>}

            <Card><SimpleDefectView severityCounts={severityCounts} defects={inspectionDateDefects}/></Card>

            <DocumentCard windfarm={selectedWindfarm} turbine={selectedTurbine}/>

            <div className='pie-container'>
                <DefectPie defects={defectsNotRepaired}
                           title='Not repaired'
                />
                <DefectPie defects={defectsSelectedForRepair}
                           title='Selected for repair'
                />
                <DefectPie defects={defectsRepaired}
                           title='Repaired'
                />
            </div>
            {initial_windfarm_id && <Card className='export-card'>
                <h2>Export repair scope</h2>
                <WplMultiSelect
                    className='select-repair-scope-dropdown'
                    title='Repair scopes'
                    selected={exportRepairScope}
                    onSelected={selectedRepairScope}
                    options={['Not repaired', 'Selected for repair', 'Repaired']}
                />
                <label className={exportImages ? 'checked' : ''} onClick={() => setExportImages(!exportImages)}>
                    <div className='my-check'>
                        {exportImages && <img src={check} alt='check'/>}
                    </div>
                    Download images with repair scope?
                </label>
                {
                    isLongExport ? <p>We started your export. Check your email in a couple of minutes.</p> : <div>
                        {exportUrl && !loadingExport && <WplButton onClick={() => {
                            window.location = exportUrl
                        }} value={'Click here to download your export'}/>}
                        {exportUrl === '' && (loadingExport ? <img src={loading} alt='loading'/> :
                            <WplButton onClick={startExport} value='Start export'/>)}
                    </div>
                }
            </Card>}
            {initial_turbine_id ? <BladeDefectTable defects={inspectionDateDefects}/> :
                <PivotTable defects={inspectionDateDefects}/>}
        </div>
    </div>)
}
