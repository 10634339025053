import React, { useState } from 'react'
import './lightning-support-page.css'
import supportImg from '../../../assets/img/support.svg'
import supportManWomanImg from '../../../assets/img/support-man-woman.svg'
import mailIcon from '../../../assets/img/mail-24px.svg'
import phoneIcon from '../../../assets/img/call-24px.svg'
import Card from "../../../components/card/Card";
import collectionArea from "../../../assets/img/Collection Area of WTG.png";
import collectionAreaTopography from "../../../assets/img/Collection-Area-Topography.png";
import DTM from "../../../assets/img/DTM.png";
import DTMSurface from "../../../assets/img/DTM-surface.png";
import confidence from "../../../assets/img/confidence-ellipse.png";
import confidenceCollection from "../../../assets/img/collection-araea-confidence-ellipse.png";
import Lightning_Density_map_2021 from '../../../assets/img/Global_lightning_density_2021.jpg'
import Lightning_Density_map_2016_2020 from '../../../assets/img/Average_global_lightning_density_2016_2020.jpg'


export default function LightningSupportPage(props) {
    const [showFaq, setShowFaq] = useState(false);
    const [showTerms, setShowTerms] = useState(false);

    return (<div className='support-page'>
        <h1>LASSIE Support Page</h1>
        <div className='split'>
            <div>
                <h1>Need support?</h1>
                <img src={supportImg} alt='support' />
                <p>If you need support when using LASSIE see beelow or please contact us.</p>
                <div>
                </div>
            </div>
            <div>
                <h1>Blade experts</h1>
                <img src={supportManWomanImg} alt='blade experts' className='icon-img' />
                <p>Do you need help or a second opinion of a lightning related damage? Our blade
                    experts are ready to help.</p>
            </div>
        </div>
        <div className='split'>
            <div>
                <a href='mailto:support@windpowerlab.com' className='call-to-action-btn'>
                    <img src={mailIcon} alt='support-email' className='icon-img' />
                    <span>support@windpowerlab.com</span>
                </a>
            </div>
            <div>
                <a href='mailto:sales@windpowerlab.com' className='call-to-action-btn'>
                    <img src={mailIcon} alt='blade-email' className='icon-img' />
                    <span>sales@windpowerlab.com</span>
                </a>
                <a href='tel:004531321006' className='call-to-action-btn'>
                    <img src={phoneIcon} alt='blade-phone' className='icon-img' />
                    <span>+45 31 321 006</span>
                </a>
            </div>
        </div>
        <Card>
            <div onClick={() => setShowFaq(!showFaq)} className='header'>
                <h2>FAQ</h2>
                <div className='spacer' />
                {<span className='show-less-more'>Show {showFaq ? 'less -' : 'more +'}</span>}
            </div>
            <div className='faq-content' hidden={!showFaq}>

                <div>
                    <h4>What is lightning?</h4>
                    <p>
                        Lightning is an electrical discharge resulting from imbalances in electrical charge
                        between storm clouds and the ground or within the clouds themselves. Most lightning occurs within clouds.

                        During a storm, particles of rain, ice, or snow collide inside storm clouds,
                        increasing the imbalance of charges. This often leads to the lower portions of storm
                        clouds becoming negatively charged. This charge difference creates
                        an imbalance that nature corrects by allowing an electrical current to
                        flow between the oppositely charged areas.
                    </p>

                    <p>
                        Lightning is being divided into two main categories. In-Cloud lightning and Cloud to ground lightning.
                    </p>
                    <p>
                        <b>In Cloud lightning</b>:<br></br>
                        A cloud flash is lightning that connects regions with opposite polarity (+/-) within one cloud or between
                        multiple clouds. In-cloud lightning usually precedes cloud-to-ground lightning.
                    </p>

                    <b> Cloud-to-ground lightning</b>: <br></br>
                    A cloud-to-ground flash consists of at least one cloud-to-ground stroke and is dangerous to life
                    and property. A cloud-to-ground stroke, also known as a stroke, is lightning that connects a charge region in
                    a cloud to the ground and is the bright, high current, visible part of lightning that touches the ground or an object.
                    <p>
                        When lightning attaches to the wind turbine blade research shows that  the tip area of the blade is more prone to receive
                        lightning strikes than all other places on the blade. [1][2][3].
                    </p>
                </div>


                <div>

                    <h4>Lightning intensity at a global scale</h4>

                    <p>
                        The distribution of lightning on Earth is far from uniform. The ideal conditions for producing lightning
                        and associated thunderstorms occur where warm, moist air rises and mixes with cold air above.
                        These conditions occur almost daily in many parts of the Earth, but only rarely in other areas.
                    </p>

                    <h3>Global lightning density 2021</h3>
                    <img src={Lightning_Density_map_2021} alt={'Lightning Density map 2021'} class="center" />

                    <h3>Average global lightning density 2016–2020</h3>
                    <img src={Lightning_Density_map_2016_2020} alt={'Lightning Density map 2016'} class="center" />
                </div>

                <div>
                    <h4>Wind turbines and lightning</h4>

                    <p>
                        Wind turbines are equipped with a lightning protection system (LPS)
                        designed to significantly reduce the risks associated with lightning strikes.
                        This system works by safely directing the electrical energy from a strike down through the turbine structure and into the ground,
                        thereby protecting critical components such as the blades, nacelle, and generator from damage.
                    </p>

                    <p>
                        The LPS consist of several part such as lightning receptors on the blades, conductive paths that channel the electrical charge safely,
                        and grounding systems that disperse the energy into the earth.
                    </p>
                </div>

                <div>
                    <h4>What is the IEC 61400-24 standard</h4>

                    <p>
                        The International Standard 61400-24 governs the lightning protection of wind turbine generators.
                        It delineates the lightning environment specific to wind turbines and its application in risk
                        assessment for these installations.
                        Moreover, it outlines the prerequisites for safeguarding blades, structural elements,
                        and electrical as well as control systems from both direct and indirect lightning effects.
                    </p>
                </div>

                <div>
                    <h4>Why is the 61400-24 standard important?</h4>

                    <p>
                        The standard outlines the magnitude of lightning strikes
                        that the Lightning Protection System (LPS) must effectively manage.
                        It serves as a benchmark to determine whether a lightning strike falls within the design parameters of the LPS or surpasses them.
                    </p>

                    <p>
                        For first stroke:
                    </p>

                    <table className="table">
                        <tr>
                            <th>Peak Current</th>
                            <tr>-100 to 200 kA</tr>
                        </tr>

                        <tr>
                            <th>Average Steepness</th>
                            <tr>200 kA/µs</tr>
                        </tr>

                        <tr>
                            <th>Specific energy</th>
                            <tr>10 MJ/Ω</tr>
                        </tr>

                        <tr>
                            <th>Total charge</th>
                            <tr>300 C</tr>
                        </tr>
                    </table>
                    <p>
                        For subsequent strokes:
                    </p>
                    <table className="table">
                        <tr>
                            <th>Peak Current</th>
                            <tr>-50 to 50 kA</tr>
                        </tr>

                        <tr>
                            <th>Average Steepness</th>
                            <tr>200 kA/µs</tr>
                        </tr>

                    </table>
                    <p>
                        Source: IEC 61400-24 (2010): Wind turbines – Part 24: Lightning protection.
                    </p>
                </div>

                <div>
                    <h4>Is a lightning strike outside the IEC certification limits?</h4>

                    <p>
                        LASSIE automatically monitors whether a lightning strikes peak current is the initial strike or a subsequent stroke,
                        while also verifying if it conforms to the certification limits of the LPS system
                    </p>
                </div>

                <div>
                    <h4>Interpreting the 2% Rule for wind turbine blade damages in the IEC Standard</h4>
                    <p>
                        The 2% damage rule in the IEC standard can be interpreted in various ways:

                        <ol>
                            <li>The 2% coefficient pertains to the efficiency of the Lightning Protection System (LPS) in a laboratory setting.</li>
                        </ol>

                        The consensus among the IEC 61400:24 committee, comprising lightning specialists from OEMs, universities, and test centers, is that the 2% statement
                        relates to the efficiency of the LPS system during the certification of a new blade. It does not directly apply to operational conditions.

                        Other interpretations of the 2% probability for LPS/LPL class I include:

                        <ol start="2">
                            <li>With a LPS/LPL class 1, there's a 2% chance of structural damage occurring.</li>
                        </ol>

                        This implies that out of 100 lightning strikes hitting a turbine or wind farm, approximately 2—regardless of their type or size—could cause structural damage.
                        This aligns more closely with reality than what we see in bullet 3, as LPS designs with a proven track record may experience lightning strikes outside of their receptors, resulting in structural damage.

                        <ol start="3">
                            <li>2% of all lightning strikes fall outside of the certification range.</li>
                        </ol>

                        However, it's improbable that 2% of all lightning strikes exceed the certification threshold, especially outside of specific regions.
                        In fact, data suggests that smaller lightning strikes, which are less predictable, are more likely to cause damage to a blade than larger ones.
                    </p>
                </div>

                <div>
                    <h4>Does the location of the wind turbine matter? </h4>

                    <p>
                        Yes it does. The IEC standard defines how the collection area should be defined on flat land or in more complex terrain.
                        On flat land the collection area is 3x the turbine height as can be seen below.
                        <img src={collectionArea} alt='collection-area' class="center" />
                    </p>

                </div>
                <div>
                    <p>
                        In more complex terrain, it is appropriate to consider the effective height of the wind turbine including the height
                        of the wind turbine position, e.g. if placed exposed on hills or ridges as shown below.
                    </p>

                    <img src={collectionAreaTopography} alt='collection-area-topography' class="center" />

                    <p>
                        LASSIE handles both scenarios and automatically calculates if a lightning strike is within the collection area of a turbine.
                        No matter if the wind turbine is located on flat land or an elevated position.

                        We are using a digital terrain model to support us in these calculations.
                    </p>
                </div>

                <div>
                    <h4>What is a Digital Terrain Model?</h4>

                    <p>
                        A Digital Terrain Model (DTM) is a detailed map showing just the natural shape of the ground without trees, buildings,
                        or other things on it, as depicted below.
                    </p>

                    <img src={DTM} alt='Digital Terrain Model' class="center" />

                    <p>
                        LASSIE is using a DTM made by NASA*. This DTM is very detailed, with measurements taken every 30 meters.
                        It means that within each square on the map, you get an average height above sea level.
                        The DTM represents this data in a digital grid format, removing anything that isn't part of the natural landscape.

                        <p>
                            * https://www.earthdata.nasa.gov/eosdis/daacs/lpdaac
                        </p>
                        An example of a DTM can be seen here:
                    </p>

                    <img src={DTMSurface} alt='Digital Terrain Model' class="center" />

                </div>

                <div>
                    <h4>What is the confidence ellipse?</h4>

                    <p>
                        The confidence ellipse is determined by three factors: its length from one end
                        to the other (semi-major length),
                        its shorter length (semi-minor length), and the angle it makes with true north.
                        The confidence ellipse is a statistical measure influenced by the accuracy of the
                        sensors detecting lightning strikes.
                        A smaller ellipse indicates more precise coverage by the network in the region.

                        LASSIE is operating with 99th percentile meaning that LASSIE expects that in 99% of
                        the time a lightning strike will fall within the P99 level and in 1% a lightning
                        will fall outside the 99th percentile.
                        Meaning that a lightning strike will fall outside the confidence ellipse.
                    </p>
                    <img src={confidence} alt='confidence ellipse' class="center" />

                </div>

                <div>
                    <h4>Probability?</h4>

                    <p>
                        The probability is determined by how much the collection area of he turbine overlaps
                        with the confidence ellipse of the lightning strike.

                    </p>
                    <img src={confidenceCollection} alt='confidence ellipse with collection area' class="center" />
                </div>


                <div>
                    <h4>How often is the data updated?</h4>

                    <p>
                        The data gets refreshed every hour, but during significant lightning events,
                        updates might be delayed.
                    </p>

                </div>

                <div>
                    <h4> Do you share my wind turbine information?</h4>

                    <p>All information and data handled in LASSIE is covered by
                        individual client agreements on confidentiality.
                        No data is being shared with 3rd party companies. </p>
                </div>
                <div>
                    <h4>References</h4>
                    <p>[1] Madsen, S. F. (2006), Interaction between electrical discharges and materials for wind turbine
                        blades particularly related to lightning protection, PhD thesis, Ørsted-DTU, The Technical Univ. of Denmark, Kongens Lyngby, Denmark.
                    </p>
                    <p>[2] Bertelsen, K., H. V. Erichsen, M. S. Jensen, and S. F. Madsen (2007), Application of numerical models to
                        determine lightning attachment points on wind turbines, in Proceedings of the International
                        Conference on Lightning and Static Electricity, IET, pp. 28–31, Paris, France.
                    </p>
                    <p>[3] Garolera, A. C., S. F. Madsen, M. Nissim, J. Myers, and J. Holboell (2016), Lightning damage to
                        wind turbine blades from wind farms in US, IEEE Trans. Power Delivery, 31(3), 1043–1049, doi:10.1109/TPWRD.2014.2370682.
                    </p>
                </div>
            </div>
        </Card>
        <Card>
            <div onClick={() => setShowTerms(!showTerms)} className='header'>
                <h2>Terms and conditions</h2>
                <div className='spacer' />
                {<span className='show-less-more'>Show {showTerms ? 'less -' : 'more +'}</span>}
            </div>
            <div className='content terms' hidden={!showTerms}>
                <div>
                    <h3>Wind Power LAB Wind Diagnostic Application Agreement</h3>
                    <p>
                        This Wind Power lAB
                        ("WPL") Wind Diagnostic Application Agreement (the "Agreement") is made and entered into by and
                        between WPL and the entity or person agreeing to these terms ("Customer"). "WPL" means Wind
                        Power
                        LAB as a legal company and its affiliates?
                    </p>
                    <p> This Agreement is
                        effective as of the date Customer receives data and login to the platform. If you are accepting
                        on
                        behalf of Customer, you represent and warrant that: (i) you have full legal authority to bind
                        Customer to this Agreement; (ii) you have read and understand this Agreement; and (iii) you
                        agree,
                        on behalf of Customer, to this Agreement. If you do not have the legal authority to bind
                        Customer,
                        please do not click to accept. This Agreement governs Customer's access to and use of the
                        Service.
                    </p>
                    <ol>
                        <li> Provision of the Service.
                            <ol>
                                <li> Service Use. Subject to this Agreement, during the Term,
                                    Customer may: (a) use the Services, and (b) integrate the Services into any
                                    Application
                                    that has material value independent of the Services. Customer may not sublicense or
                                    transfer these rights.
                                </li>
                                <li> Interface/UI. WPL will provide the Service to Customer. As part
                                    of receiving the Service, Customer will have access to the UI, through which
                                    Customer
                                    may administer the Service, and change data provided by WPL. It is Customers sole
                                    responsible to ensure the validity of data entries made by Customer.
                                </li>
                                <li> Facilities. WPL uses Google Cloud Services to provide the
                                    Service and as such adhere to at least industry standard systems and procedures to
                                    (i)
                                    ensure the security and confidentiality of an Application and Customer Data, (ii)
                                    protect against anticipated threats or hazards to the security or integrity of an
                                    Application and Customer Data, and (iii) protect against unauthorized access to or
                                    use
                                    of an Application and Customer Data.
                                </li>
                                <li> Data Location. Data location is by default within the EU unless
                                    specifically requested by the Costumer prior to setup to be in another location.
                                </li>
                                <li> Accounts. Customer must have an Account and a Token (if
                                    applicable) to use the Service, and is responsible for the information it provides
                                    to
                                    create the Account, the security of the Token and its passwords for the Account, and
                                    for
                                    any use of its Account and the Token. If Customer becomes aware of any unauthorized
                                    use
                                    of its password, its Account or the Token, Customer will notify WPL as promptly as
                                    possible.
                                </li>
                                <li> New Features and Functionality. WPL may: (i) make new features
                                    or functionality available from time to time through the Service the use of which
                                    may be
                                    contingent upon Customer's agreement to additional terms.
                                </li>
                                <li> Modifications.
                                    <ol>
                                        <li> a.&nbsp;To the Service. WPL may make commercially reasonable
                                            updates to the Service from time to time. If WPL makes a material change to
                                            the
                                            Service, WPL will inform Customer.
                                        </li>
                                        <li> b.&nbsp;To the Agreement. WPL may make changes to
                                            this
                                            Agreement, including pricing (and any linked documents) from time to time.
                                            WPL will
                                            provide at least 90 days’ advance notice of any pricing changes by sending
                                            an email
                                            to the Customer.
                                        </li>
                                    </ol>
                                </li>
                            </ol>
                        </li>
                        <li> Payment Terms.
                            <ol>
                                <li> Free Quota. As part of WPL standard delivery of reports the
                                    Customer will receive free access to the Service for a 60 days period. After which
                                    the
                                    Service will be closed unless Costumer agrees to a subscription.
                                </li>
                                <li> Billing. Individual billing plans for Wind Diagnostic Application
                                    is agreement with each client using the platform
                                </li>
                                <li> Invoice Disputes &amp; Refunds. All disputes and refunds are
                                    handled by Wind Power LAB or its affiliates.
                                </li>
                                <li> Delinquent Payments; Suspension. Late payments may bear interest
                                    at the rate of 1.5% per month (or the highest rate permitted by law, if less) from
                                    the
                                    payment due date until paid in full. Customer will be responsible for all reasonable
                                    expenses (including attorneys' fees) incurred by WPL in collecting such delinquent
                                    amounts. If Customer is late on payment for the Service, WPL may Suspend the
                                    Services or
                                    terminate the Agreement.
                                </li>
                            </ol>
                        </li>
                        <li> Customer Obligations.
                            <ol>
                                <li> Compliance. Customer is solely responsible for its Customer Data
                                    and any modifications made to Customer Data using the Service.
                                </li>
                                <li> Restrictions. Customer will not, and will not allow third
                                    parties under its control to: (a) copy, modify, create a derivative work of, reverse
                                    engineer, decompile, translate, disassemble, or otherwise attempt to extract any or
                                    all
                                    of the source code of the Services Access the Service in a manner intended to avoid
                                    incurring Fees or exceed usage limits or quotas.
                                </li>
                            </ol>
                        </li>
                        <li> Suspension.
                            <ol>
                                <li> Suspension. WPL may immediately Suspend all or part of
                                    Customer's use of the Service if: (a) WPL believes Customer's or any Customer End
                                    User's
                                    use of the Service could adversely impact the Service, other customers' or their end
                                    users' use of the Service; (b) there is suspected unauthorized third-party access to
                                    the
                                    Services or (c) Customer is in breach of Section 3.2 (Restrictions). WPL will notify
                                    Customer of the basis for the Suspension as soon as is reasonably possible.
                                </li>
                            </ol>
                        </li>
                        <li> Intellectual Property Rights; Use of Customer Data; Feedback
                            <ol>
                                <li> Intellectual Property Rights. Except as expressly set forth in
                                    this Agreement, this Agreement does not grant either party any rights, implied or
                                    otherwise, to the other's content or any of the other's intellectual property. As
                                    between the parties, Customer owns all Intellectual Property Rights in Customer Data
                                    and
                                    the Application or Project (if applicable), and Wind Power LAB owns all Intellectual
                                    Property Rights in the Services and Software.
                                </li>
                                <li> Use of Customer Data. WPL will access and process Customer Data
                                    to deliver blade defect assessment, repair recommendation, engineering advice and
                                    other
                                    outputs on Customer Data. In the processing, Customer Data outputs will be used for
                                    improvement of WPL algorithms and processes.
                                </li>
                                <li> Customer Feedback. If Customer provides WPL Feedback about the
                                    Service, then WPL may use that information without obligation to Customer, and
                                    Customer
                                    hereby irrevocably assigns to WPL all right, title, and interest in that Feedback.
                                </li>
                            </ol>
                        </li>
                        <li> Technical Support Services
                            <ol>
                                <li> By WPL. Subject to payment of applicable support Fees, WPL will
                                    provide TSS to Customer during the Term in accordance with the TSS Guidelines.
                                </li>
                            </ol>
                        </li>
                        <li> Deprecation of Service
                            <ol>
                                <li> Deprecation Policy. WPL will announce if it intends to
                                    discontinue or make backwards incompatible changes to the Service via email to
                                    Customer.
                                </li>
                            </ol>
                        </li>
                        <li> Confidential Information. All information and data handled in the
                            Wind Diagnostic Application is covered by individual client agreements on confidentiality.
                            <ol>
                                <li> Obligations. The recipient will not disclose the
                                    Confidential Information, except to Affiliates, employees, agents or professional
                                    advisors who need to know it and who have agreed in writing (or in the case of
                                    professional advisors are otherwise bound) to keep it confidential. The recipient
                                    will ensure that those people and entities use the received Confidential Information
                                    only to exercise rights and fulfill obligations under this Agreement, while using
                                    reasonable care to keep it confidential.
                                </li>
                                <li> Required Disclosure. Notwithstanding any provision to the
                                    contrary in this Agreement, the recipient may also disclose Confidential Information
                                    to the extent required by applicable Legal Process; provided that the recipient uses
                                    commercially reasonable efforts to: (i) promptly notify the other party of such
                                    disclosure before disclosing; and (ii) comply with the other party's reasonable
                                    requests regarding its efforts to oppose the disclosure. Notwithstanding the
                                    foregoing, subsections (i) and (ii) above will not apply if the recipient determines
                                    that complying with (i) and (ii) could: (a) result in a violation of Legal Process;
                                    (b) obstruct a governmental investigation; and/or (c) lead to death or serious
                                    physical harm to an individual. As between the parties, Customer is responsible for
                                    responding to all third party requests concerning its use and Customer End Users'
                                    use of the Services.
                                </li>
                            </ol>
                        </li>
                        <li> Term and Termination.
                            <ol>
                                <li> Agreement Term. The "Term" of this Agreement will begin on the
                                    Effective Date and continue until the Agreement is terminated as set forth in
                                    Section 9
                                    of this Agreement.
                                </li>
                                <li> Termination for Breach. Either party may terminate this
                                    Agreement for breach if: (i) the other party is in material breach of the Agreement
                                    and
                                    fails to cure that breach within thirty days after receipt of written notice; (ii)
                                    the
                                    other party ceases its business operations or becomes subject to insolvency
                                    proceedings
                                    and the proceedings are not dismissed within ninety days; or (iii) the other party
                                    is in
                                    material breach of this Agreement more than two times notwithstanding any cure of
                                    such
                                    breaches. In addition, WPL may terminate any, all, or any portion of the Services or
                                    Projects, if Customer meets any of the conditions in Section 9.2(i), (ii), and/or
                                    (iii).
                                </li>
                                <li> Effect of Termination. If the Agreement is terminated, then: (i)
                                    the rights granted by one party to the other will immediately cease; (ii) all Fees
                                    owed
                                    by Customer to WPL are immediately due upon receipt of invoice; and (iii) upon
                                    request,
                                    each party will use commercially reasonable efforts to return or destroy all
                                    Confidential Information of the other party.
                                </li>
                            </ol>
                        </li>
                        <li> Representations and Warranties. Each party represents and warrants
                            that: (a) it has full power and authority to enter into the Agreement; and (b) it will
                            comply with all laws and regulations applicable to its provision, or use, of the Services,
                            as applicable. Wind Power LAB warrants that it will provide the Services in accordance with
                            the applicable SLA (if any).
                        </li>
                        <li> Disclaimer. Wind Power LAB cannot be held responsible for missed
                            defects that are not clearly distinguishable in images or the development of defects after
                            the inspection or the assessment of the wind turbine blade is completed. Wind Power LAB is
                            using the metadata provided with the inspection images to create the assessment report. Wind
                            Power LAB takes no responsibility for errors in metadata values unless Wind Power LAB is
                            conducting the inspection. Some internal damages are not visible when inspecting the turbine
                            using ground based inspection or drone based inspection equipment. These damages can only be
                            found using internal inspection equipment or by testing with tools directly on the blade.
                            Wind Power LAB cannot be held responsible for identification of internal defects that are
                            not clearly distinguishable on provided images
                        </li>
                        <li> Limitation of Liability.
                            <ol>
                                <li> Limitation on Indirect Liability. TO THE MAXIMUM EXTENT
                                    PERMITTED BY APPLICABLE LAW, NEITHER PARTY, NOR WIND POWER LAB´S SUPPLIERS, WILL BE
                                    LIABLE UNDER THIS AGREEMENT FOR LOST REVENUES OR INDIRECT, SPECIAL, INCIDENTAL,
                                    CONSEQUENTIAL, EXEMPLARY, OR PUNITIVE DAMAGES, EVEN IF THE PARTY KNEW OR SHOULD HAVE
                                    KNOWN THAT SUCH DAMAGES WERE POSSIBLE AND EVEN IF DIRECT DAMAGES DO NOT SATISFY A
                                    REMEDY.
                                </li>
                                <li> Limitation on Amount of Liability. TO THE MAXIMUM EXTENT
                                    PERMITTED BY APPLICABLE LAW, NEITHER PARTY, NOR WIND POWER LAB'S SUPPLIERS, MAY BE
                                    HELD
                                    LIABLE UNDER THIS AGREEMENT FOR MORE THAN THE AMOUNT PAID BY CUSTOMER TO WIND POWER
                                    LAB
                                    UNDER THIS AGREEMENT DURING THE TWELVE MONTHS PRIOR TO THE EVENT GIVING RISE TO
                                    LIABILITY.
                                </li>
                                <li> Exceptions to Limitations. These limitations of liability do not
                                    apply to violations of a party's Intellectual Property Rights by the other party,
                                    indemnification obligations, or Customer's payment obligations.
                                </li>
                            </ol>
                        </li>
                        <li> Indemnification.
                            <ol>
                                <li> By Customer. Unless prohibited by applicable law, Customer will
                                    defend and indemnify Wind Power LAB and its Affiliates against Indemnified
                                    Liabilities
                                    in any Third-Party Legal Proceeding to the extent arising from: (i) any Application,
                                    Project, Instance, Customer Data or Customer Brand Features; or (ii) Customer's, or
                                    Customer End Users', use of the Services in violation of the AUP.
                                </li>
                                <li> By Wind Power LAB. Wind Power LAB will defend and indemnify
                                    Customer and its Affiliates against Indemnified Liabilities in any Third-Party Legal
                                    Proceeding to the extent arising solely from an Allegation that use of (a) Wind
                                    Power
                                    LAB´s technology used to provide the Services or (b) any Wind Power LAB Brand
                                    Feature
                                    infringes or misappropriates the third party's patent, copyright, trade secret, or
                                    trademark.
                                </li>
                                <li> Exclusions. This Section 14 will not apply to the extent the
                                    underlying Allegation arises from: <ol>
                                        <li>a. the indemnified party's breach of this Agreement;</li>
                                        <li> b. modifications to the indemnifying party's technology
                                            or Brand Features by anyone other than the indemnifying party;
                                        </li>
                                        <li> c. combination of the indemnifying party's technology or
                                            Brand Features with materials not provided by the indemnifying party; or
                                        </li>
                                        <li>d. use of non-current or unsupported versions of the
                                            Services or Brand Features;
                                        </li>
                                    </ol>
                                </li>
                            </ol>
                        </li>
                    </ol>
                </div>
            </div>
        </Card>
    </div>)
}
