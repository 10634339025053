import React, { useState } from "react";
import "./wpl-file-upload.css";
import uploadLogo from "../../assets/img/cloud_upload-34px.svg";
import documentLogo from "../../assets/img/document_logo.svg";
import WplPopup from "../wpl-popup-window/WplPopup";
import "../wpl-table/wpl-table.css";

export default function WplFileUpload({
  didSelectFile,
  asPopup = true,
  displayFiles = true,
  text = null,
  style = null,
  fullWidth = false,
  disabled = false,
  warningMessage = "Document upload is only available for one turbine at a time.",
}) {
  const [showUploadPopup, setShowUploadPopup] = useState(false);
  const [selectedFiles, setSelectedFiles] = useState({});
  const [dragging, setDragging] = useState(false);

  const dragEnter = (e) => {
    e.preventDefault();
    setDragging(true);
  };
  const dragLeave = (e) => {
    e.preventDefault();
    setDragging(false);
  };
  const fileDrop = (e) => {
    e.preventDefault();
    const files = e.dataTransfer.files;
    setDragging(false);

    addFiles(Array.from(files));
  };

  function addFiles(files) {
    const selectedDict = {};
    files.forEach((f) => {
      selectedDict[f.name] = f;
    });
    files.forEach((file) => {
      let reader = new FileReader();
      reader.onloadend = () => {
        setSelectedFiles((prev) => ({
          ...prev,
          [file.name]: {
            ...file,
            loading: true,
          },
        }));
        const ok = didSelectFile(file.name, reader.result, () => {
          setSelectedFiles((prev) => ({
            ...prev,
            [file.name]: {
              ...file,
              loading: false,
              status: "done",
            },
          }));
        });
        if (!ok) {
          setSelectedFiles((prev) => ({
            ...prev,
            [file.name]: {
              ...file,
              loading: false,
              status: "failed",
            },
          }));
        } else {
          setSelectedFiles((prev) => ({
            ...prev,
            [file.name]: {
              ...file,
              loading: true,
            },
          }));
        }
      };

      reader.readAsDataURL(file);
    });
  }

  function handleFileUpload(e) {
    e.preventDefault();
    if (disabled) return;
    addFiles(Array.from(e.target.files));
  }

  function renderInside() {
    return (
      <div className="wpl-file-upload" style={style}>
        {disabled && (
          <div className="overlay">
            <div className="warning-message">{warningMessage}</div>
          </div>
        )}
        <div
          className={`upload-field ${dragging && "dragging"}`}
          style={{
            width: fullWidth ? "100%" : "calc(100% - 70px)",
            pointerEvents: disabled ? "none" : "auto", 
            opacity: disabled ? 0.5 : 1, 
          }}
          onDragEnter={dragEnter}
          onDragLeave={dragLeave}
          onDragOver={(e) => e.preventDefault()}
          onDrop={fileDrop}
        >
          <img src={uploadLogo} alt="drop files here" />
          <p>{text === null ? "Drag and drop files, or" : text}</p>
          <label
            className={`styled-file-selector ${dragging ? "dragging" : ""}`}
          >
            <input
              className="file-selector"
              type="file"
              onChange={handleFileUpload}
            />
            Browse Files
          </label>
        </div>
        {displayFiles && (
          <div className="file-list">
            <table className="wpl-table">
              <thead>
                <tr style={{ textAlign: "left" }}>
                  <th>Title</th>
                  <th>Status</th>
                </tr>
              </thead>
              <tbody>
                {Object.keys(selectedFiles).map((fileName) => (
                  <tr key={fileName}>
                    <td>{fileName}</td>
                    <td>
                      {selectedFiles[fileName].loading ? "loading" : "done"}
                    </td>
                  </tr>
                ))}
                {Object.keys(selectedFiles).length === 0 && (
                  <tr>
                    <td colSpan={3}>No files</td>
                  </tr>
                )}
              </tbody>
            </table>
          </div>
        )}
      </div>
    );
  }

  if (!asPopup) {
    return <div className="wpl-file-upload-page">{renderInside()}</div>;
  }

  return (
    <div className="wpl-file-upload-page">
      <div
        className="open-window-button"
        onClick={() => setShowUploadPopup(true)}
      >
        <img src={documentLogo} alt="click to open document window" />
      </div>
      <WplPopup showPopup={showUploadPopup} closePopup={setShowUploadPopup}>
        <h1>Upload documents</h1>
        <div>&nbsp;</div>
        {renderInside()}
      </WplPopup>
    </div>
  );
}
