import React, { useState } from "react";
import "./wpl-filter-input.css";

export default function WplFilterInput({
  label,
  type = "number",
  value,
  onChange,
  placeholder = " ",
  inputClassName = "",
  containerClassName = "",
  labelClassName = "",
  ...props
}) {
  const [isActive, setIsActive] = useState(value !== "");

  const handleInputChange = (e) => {
    onChange(e);
    setIsActive(e.target.value !== "");
  };

  return (
    <div className={`filter-input-area ${containerClassName}`}>
      <input
        className={`filter-input ${inputClassName}`}
        type={type}
        value={value}
        onChange={handleInputChange}
        onFocus={() => setIsActive(true)}
        onBlur={() => setIsActive(value !== "")}
        placeholder={placeholder}
        {...props}
      />
      <label
        className={
          isActive
            ? `input-label Active ${labelClassName}`
            : `input-label ${labelClassName}`
        }
      >
        {label}
      </label>
    </div>
  );
}
