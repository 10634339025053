import React from "react";
import { NavLink, Outlet, useLocation } from "react-router-dom";
import "./lightning-settings-page.css";

export default function LightningSettingsParent() {
  const location = useLocation();

  return (
    <div className="lightning-settings-container">
      <h1>Settings</h1>
      <div className="lightning-settings">
        <div className="subsettings-link-list">
          <NavLink
            to="notifications"
            className={({ isActive }) => (isActive ? "active-link" : "")}
          >
            Notifications
          </NavLink>
          <NavLink
            to="account"
            className={({ isActive }) => (isActive ? "active-link" : "")}
          >
            Account
          </NavLink>
        </div>
        <div className="settings-wrapper">
          <Outlet />
        </div>
      </div>
    </div>
  );
}
