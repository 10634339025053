import React from "react";
import WplSelectInput from "../wpl-select-input/WplSelectInput";
import WplSwitch from "../wpl-switch/wplSwitch";
import WplFilterInput from "../wpl-filter-input/WplFilterInput";
import closeIcon from "../../assets/img/cancel.png";
import "../../page/lightning/turbine-manager/turbine-manager.css";

export default function AdvanceFilters({
  showAdvanceFilter,
  modelFilter,
  setModelFilter,
  resetFilters,
  modelOptions,
  peakCurrentRange,
  handleMinChange,
  handleMaxChange,
  proximityFilter,
  handleDistanceChange,
  probabilityFilter,
  handleProbabilityChange,
}) {
  return (
    <div className={`filters-dropdown ${showAdvanceFilter ? "show" : ""}`}>
      <div className="filters-wrapper">
        <div
          className="clear-badge clear-filters"
          onClick={() => resetFilters()}
        >
          <p className="turbine-name">Reset filters</p>
          <img src={closeIcon} className="clear-icon" alt="close icon" />
        </div>
        <div className="two-columns-filter-area">
          <div className="input-row">
            <WplSelectInput
              label="Turbine model"
              options={modelOptions}
              value={modelFilter || ""}
              onChange={setModelFilter}
              placeholder="Select a turbine"
            />
          </div>
  
          <div className="input-row">
            <p className="label">Peak Current Range</p>
          </div>

          <WplFilterInput
            inputClassName={"turbine-manager-input"}
            label={"min kA"}
            value={peakCurrentRange[0] ?? ""}
            onChange={handleMinChange}
          />

          <WplFilterInput
            inputClassName={"turbine-manager-input"}
            label={"max kA"}
            value={peakCurrentRange[1] ?? ""}
            onChange={handleMaxChange}
          />

          <p className="label">Distance</p>
          <p className="label">Probability</p>
          
          <WplFilterInput
            inputClassName={"turbine-manager-input"}
            label={"meters"}
            value={proximityFilter ?? ""}
            onChange={handleDistanceChange}
            min={0}
            max={2000}
          />

          <WplFilterInput
            label={"%"}
            inputClassName={"turbine-manager-input"}
            value={probabilityFilter ?? ""}
            onChange={handleProbabilityChange}
            min={0}
            max={100}
          />
        </div>
      </div>
    </div>
  );
}
