import React, { useState, useEffect, useContext } from "react";
import { Link, useNavigate } from "react-router-dom";
import { LassieContext } from "../../../context/LassieProvider";
import WplSwitch from "../../../components/wpl-switch/wplSwitch";
import settingsIcon from "../../../assets/setting.png";
import AlertPopup from "../../../components/alert-popup/AlertPopup";
import loadingSvg from "../../../assets/loading.svg";
import "./lightning-notifications-settings.css";

export default function LightningNotificationsSettings() {
  const navigate = useNavigate();
  const {
    windfarms,
    notificationsOverview,
    settingsLoading,
    fetchScenariosOverview,
    selectedWindfarm,
    setSelectedWindfarm,
    updateScenarioStatus,
    notificationUpdate,
  } = useContext(LassieContext);
  const [showDisclaimer, setShowDisclaimer] = useState(false);

  useEffect(() => {
    fetchScenariosOverview();
  }, []);

  const handleSelectWindfarm = (windfarm) => {
    setSelectedWindfarm(windfarm);
    setShowDisclaimer(true);
  };

  return (
    <div className="notifications-settings-container">
      <h4>Manage notifications</h4>

      {settingsLoading ? (
        <div className="overlay loading">
          <img src={loadingSvg} alt="loading" />
        </div>
      ) : (
        <div className="cards-area">
          {windfarms &&
            notificationsOverview &&
            windfarms.map((wf) => (
              <div className="notification-card" key={wf.id}>
                <div
                  className="wf-name-area"
                  onClick={() => handleSelectWindfarm(wf)}
                >
                  <h4 className="windfarm-name">{wf.name}</h4>
                  <div className="settings-label-area">
                    <p className="settings-label">Edit settings</p>
                    <img src={settingsIcon} className="card-icon" alt="icon" />
                  </div>
                </div>
                <div className="notification-area">
                  {notificationsOverview[wf.id] &&
                    notificationsOverview[wf.id].map((n, i) => {
                      if (!n.hasTriggers) return null;
                      return (
                        <div
                          key={i}
                          className={`notification ${
                            notificationUpdate ? "updating-notification" : ""
                          }`}
                        >
                          <p>{n.title}</p>
                          <WplSwitch
                            key={i}
                            value={n.isSubscribed}
                            onChanged={() => updateScenarioStatus(n, wf.id)}
                          />
                        </div>
                      );
                    })}
                </div>
              </div>
            ))}
        </div>
      )}

      {showDisclaimer && (
        <AlertPopup
          showPopup={showDisclaimer}
          closePopup={setShowDisclaimer}
          status={"warning"}
          title={"Disclaimer"}
          buttonText={"I agree"}
          action={() => navigate(`windfarm/${selectedWindfarm.id}`)}
        >
          <div>
            Before proceeding, please confirm that you understand the
            implications of adjusting these settings, as they directly influence
            the notifications and triggers related to windfarm activities.
            Changes are not recommended unless you fully understand their
            impact.
            <p className="disclaimer-text">
              For more information, visit our
              <Link to="/lassie/support" className="faq-link">
                FAQ
              </Link>
              to learn how these settings work.
            </p>
          </div>
        </AlertPopup>
      )}
    </div>
  );
}
