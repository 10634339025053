import React, {useCallback, useEffect, useState} from 'react'
import './lightning-admin.css'
import {lightningSubscriberAPI, userAPI} from "../../../api";
import WplInput from "../../../components/wpl-input/WplInput";
import AdminHeader from "../admin-header/AdminHeader";
import {LassieApi} from "../../../api_client/Lassie";


export default function LightningAdmin() {
    const [selectedEmail, setSelectedEmail] = useState('');
    const [selectedUser, setSelectedUser] = useState(null);
    const [selectedUserConnections, setSelectedUserConnections] = useState([]);

    const [users, setUsers] = useState([]);
    const [windfarms, setWindfarms] = useState([]);

    const [search, setSearch] = useState('');

    useEffect(() => {
        userAPI.listUsers(false).then(setUsers)
        LassieApi.list_windfarms.f().then(setWindfarms)
    }, []);

    useEffect(() => {
        if (!selectedEmail) return;
        const user = users.find(e => e.email === selectedEmail)
        setSelectedUser(user)

        lightningSubscriberAPI.list_user_subscriptions_by_user_id(user.id).then(setSelectedUserConnections)
    }, [selectedEmail, users])

    const addRelation = useCallback((rel1) => {
        if (!selectedUserConnections.some(s => s.windfarm_id === rel1.id)) {
            lightningSubscriberAPI.subscribe(selectedUser.id, rel1.id).then(setSelectedUserConnections)
            console.log(`Added ${selectedUser.email} to ${rel1.name}`)
        } else {
            console.log(`Removed ${selectedUser.email} from ${rel1.name}`)
            lightningSubscriberAPI.unsubscribe(selectedUser.id, rel1.id).then(setSelectedUserConnections)
        }
    }, [selectedUserConnections, selectedUser]);

    return (<div className={'lightning-admin-page'}>
        <AdminHeader/>
        <div className={'lightning-admin'}>
            <div className='search-bar-wrapper'>
                <WplInput title='User'
                          onChanged={setSelectedEmail}
                          value={selectedEmail}
                          placeholder='search...'
                          options={users.map(e => e.email)}
                          onlySelectableOptions
                />
            </div>
            {selectedUser && <WplInput placeholder='windfarm name...' value={search} onChanged={setSearch} clearable/>}
            {selectedUser && <div className='table-scroll'>
                <table className='entity-wrapper'>
                    <tbody>
                    {windfarms.filter(wf => !search || wf.name.toLowerCase().indexOf(search.toLowerCase()) > -1).map(windfarm =>
                        <tr key={windfarm.id}>
                            <td>{windfarm.name}</td>
                            <td><WplInput type='checkbox'
                                          value={selectedUserConnections.some(s => s.windfarm_id === windfarm.id)}
                                          onChanged={_ => {
                                              addRelation(windfarm)
                                          }}/></td>
                        </tr>)}
                    </tbody>
                </table>
            </div>}
        </div>
    </div>)
}
