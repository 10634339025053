import React, { useEffect, useMemo, useState, useContext } from "react";
import { LassieContext } from "../../../context/LassieProvider";
import { useParams } from "react-router-dom";
import { toPrettyTimeSince } from "../../../prettyDate";
import InfoCards from "../../../components/info-card/InfoCard";
import WplButton from "../../../components/wpl-button/WplButton";
import CenteredPopup from "../../../components/centered-popup/CenteredPopup";
import OlMap from "../../../components/ol-map/OlMap";
import { useSyncSelectedWindfarm } from "../../../hooks/useSyncSelectedWindfarm ";
import TurbineManager from "../turbine-manager/TurbineManager";
import turbineManagerIcon from "../../../assets/img/turbine-manager-icon.png";
import turbineManagerIconColor from "../../../assets/img/turbine-manager-icon-color.png";
import "./lightning-windfarm-page.css";

export default function LightningWindfarmPage() {
  const [showTurbineManager, setShowTurbineManager] = useState(false);

  const { windfarms, wfStatistics, fetchStatistics, fetchTurbines, turbines } =
    useContext(LassieContext);

  let { windfarm_id } = useParams();
  if (windfarm_id) {
    windfarm_id = parseInt(windfarm_id);
  }

  const windfarm = useMemo(() => {
    if (!windfarms || !windfarm_id) return null;
    return windfarms.find((wf) => wf.id === parseInt(windfarm_id));
  }, [windfarms, windfarm_id]);

  useEffect(() => {
    if (!windfarm_id) return;
    fetchStatistics(windfarm_id);
    fetchTurbines(windfarm_id);
  }, [windfarm_id]);

  useSyncSelectedWindfarm(windfarm_id);

  return (
    <div className="lightning-windfarm-page">
      <CenteredPopup
        className="turbine-manager turbine-list-popup"
        showPopup={showTurbineManager}
        closePopup={setShowTurbineManager}
      >
        <TurbineManager />
      </CenteredPopup>

      <div className="statistics-container">
        <div>
          <h1 style={{ marginBottom: 0 }}>{windfarm && windfarm.name}</h1>
          {windfarm && (
            <p style={{ color: "dimgrey", marginTop: 0, marginBottom: 20 }}>
              Latest data acquisition:{" "}
              {toPrettyTimeSince(new Date(windfarm.latest_data))}
            </p>
          )}
        </div>

        <div className="lightning-activity-wrapper">
          <InfoCards statistics={wfStatistics} />
        </div>
        <div className="turbine-manager-wrapper">
          <button
            className="turbine-manager-btn"
            value="Turbine manager"
            onClick={() => setShowTurbineManager(true)}
          >
            Turbine Manager
            <img
              src={turbineManagerIcon}
              className="turbine-manager-icon default-icon"
            />
            <img
              src={turbineManagerIconColor}
              className="turbine-manager-icon hover-icon"
            />
          </button>
        </div>
      </div>

      {wfStatistics.length > 0 && turbines.length > 0 && (
        <div className="map-wrapper">
          <OlMap
            key="main-map"
            lightning_strikes={[
              ...turbines.map((t) => t.turbine_lightning),
            ].flat()}
            turbines={turbines}
          />
        </div>
      )}
    </div>
  );
}
