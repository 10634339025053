import React, {
  useCallback,
  useMemo,
  useRef,
  useState,
} from "react";
import "./turbine-lightning-ruler.css";
import turbineIcon from "../../assets/turbine_img.png";
import blackLightning from "../../assets/black_lightning.svg";


export default function TurbineLightningRuler({
  strikes,
  from = null,
  to = null,
  onStrikeHover,
  onMouseLeave
  
}) {
  const [closestTo, setClosestTo] = useState(null);
  const trackRef = useRef(null);

  const maxDistance = useMemo(() => {
    if (strikes.length === 0) return Math.max(2, from ?? 2, to ?? 2);

    const closest = Math.ceil(Math.max(...strikes.map((s) => s.distance_km)));

    return Math.max(closest, 2, from ?? closest, to ?? 2);
  }, [strikes, from, to]);

  const mouseMove = useCallback(
    (event) => {
      if (!trackRef.current || !strikes.length) return;
      const mouseLeft = event.clientX;
      const rect = trackRef.current.getBoundingClientRect();

      const areaLeft = rect.left;
      const percentualDistFromLeft = (mouseLeft - areaLeft) / rect.width;

      const sorted = [...strikes].sort((a, b) => {
        const ad = Math.abs(
          a.distance_km / maxDistance - percentualDistFromLeft
        );
        const bd = Math.abs(
          b.distance_km / maxDistance - percentualDistFromLeft
        );
        return ad - bd;
      });
      const closestStrike = sorted[0];
      setClosestTo(closestStrike);

      if (onStrikeHover) {
        onStrikeHover(closestStrike);
      }
    },
    [trackRef, strikes, maxDistance, onStrikeHover]
  );

  const tickerCount = Math.min(Math.ceil(maxDistance / 0.5), 5);

  return (
    <div className={"turbine-lightning-ruler"}>
      <div
        className="lightning-track"
        ref={trackRef}
        onMouseMove={mouseMove}
        onMouseLeave={() => {
          setClosestTo(null);
          if (onMouseLeave) onMouseLeave();
        }}
      >
        <img src={turbineIcon} alt="turbine" className="turbine-icon" />
        {strikes.map((strike, idx) => {
          const uncertaintyPercent = Math.round(
            (strike.distance_km_uncertainty / maxDistance) * 100
          );
          return (
            <div
              className="lightning-image-wrapper"
              key={idx}
              style={{
                left: `calc(${Math.round(
                  (strike.distance_km / maxDistance) * 100
                )}% + 8px)`,
                width: `${uncertaintyPercent}%`,
              }}
            >
              <img
                className={`lightning-image ${
                  closestTo && closestTo.id === strike.id ? "hovering" : ""
                }`}
                src={blackLightning}
                alt="lightning-strike"
              />
              <div className="uncertainty-bar" />
            </div>
          );
        })}
        {from !== null && to !== null && (
          <div
            className="progress"
            style={{
              left: `calc(${(to / maxDistance) * 100}% - 8px)`,
              width: `${((from - to) / maxDistance) * 100}%`,
            }}
          />
        )}
      </div>
      <div className="distance-track">
        {Array(tickerCount + 1)
          .fill(0)
          .map((_, idx) => (
            <div
              key={idx}
              className="notch"
              style={{
                position: "absolute",
                left: `calc(${Math.round((idx / tickerCount) * 100)}% + 3px)`,
              }}
            />
          ))}
        {Array(tickerCount + 1)
          .fill(0)
          .map((_, idx) => (
            <label
              key={idx}
              style={{
                position: "absolute",
                left: `calc(${Math.round((idx / tickerCount) * 100)}%)`,
              }}
            >
              {Math.round(idx * (maxDistance / tickerCount) * 10) * 100}m
            </label>
          ))}
      </div>
    </div>
  );
}
