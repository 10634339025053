import React, { useEffect, useMemo, useState, useContext } from "react";
import { LassieContext } from "../../../context/LassieProvider";
import { useParams } from "react-router-dom";
import { useSyncSelectedWindfarm } from "../../../hooks/useSyncSelectedWindfarm ";
import { lightningScenarioAPI } from "../../../api";
import WPLAnimatedInput from "../../../components/wpl-animated-input/WplAnimatedInput";
import CenteredPopup from "../../../components/centered-popup/CenteredPopup";
import notificationIcon from "../../../assets/notification.png";
import newTriggerIcon from "../../../assets/plus.png";
import WplButton from "../../../components/wpl-button/WplButton";
import WplSwitch from "../../../components/wpl-switch/wplSwitch";
import loadingSvg from "../../../assets/loading.svg";
import WplTwoButtonInput from "../../../components/wpl-two-button-input/wplTwoButtonInput";
import infoIcon from "../../../assets/img/map-info.png";

import "./lightning-wf-notification-settings.css";

export default function LightningWfNotificationSettings() {
  const {
    windfarms,
    notificationsOverview,
    updateScenarioStatus,
    settingsLoading,
    setSettingsLoading,
    fetchScenariosOverview,
  } = useContext(LassieContext);
  const [showPopup, setShowPopup] = useState({
    state: false,
    type: "",
    scenario_id: null,
    trigger_exists: false,
  });
  const [scenarios, setScenarios] = useState([]);
  const [scenarioTitle, setScenarioTitle] = useState("");
  const [scenarioDescription, setScenarioDescription] = useState("");
  const [selectedCard, setSelectedCard] = useState(null);
  const [triggers, setTriggers] = useState({
    inside: {
      trigger_type: "inside-cert",
      min_probability: 9,
      max_distance_km: null,
    },
    outside: {
      trigger_type: "outside-cert",
      min_probability: 9,
      max_distance_km: null,
    },
    custom: {
      is_subsequent: false,
      min_peak_current: null,
      max_peak_current: null,
      min_probability: null,
      max_distance_km: null,
    },
  });

  let { windfarm_id } = useParams();
  if (windfarm_id) {
    windfarm_id = parseInt(windfarm_id);
  }

  const windfarm = useMemo(() => {
    if (!windfarms || !windfarm_id) return null;
    return windfarms.find((wf) => wf.id === parseInt(windfarm_id));
  }, [windfarms, windfarm_id]);

  const fetchScenarios = async () => {
    try {
      const scenariosData =
        await lightningScenarioAPI.list_scenarios_by_windfarm_id(windfarm_id);
      setScenarios(scenariosData);
    } catch (error) {
      console.log(error);
    }
  };

  useEffect(() => {
    fetchScenarios();
    fetchScenariosOverview(windfarm_id);
  }, [windfarm_id]);

  const createScenario = async (scenarioTitle, scenarioDescription) => {
    const immediate_action = "";
    setSettingsLoading(true);
    try {
      const values = {
        title: scenarioTitle,
        immediate_action,
        description: scenarioDescription,
        windfarm_id,
      };

      await lightningScenarioAPI.create_scenario(values);
    } catch (error) {
      console.log(error);
    } finally {
      initializeScenario();
    }
  };

  const createCustomTrigger = async () => {
    const values = selectedCard === "custom" && {
      ...triggers.custom,
      min_probability:
        triggers.custom.min_probability === 0 ||
        triggers.custom.min_probability == null
          ? null
          : triggers.custom.min_probability / 100,
      max_distance_km:
        triggers.custom.max_distance_km === 0 ||
        triggers.custom.max_distance_km == null
          ? null
          : triggers.custom.max_distance_km / 1000,
    };

    setSettingsLoading(true);
    try {
      await lightningScenarioAPI.create_scenario_trigger(
        showPopup.scenario_id,
        values
      );
    } catch (error) {
      console.log("There was an error creating a custom trigger: " + error);
    } finally {
      initializeScenario();
    }
  };

  const createPredefinedTrigger = async () => {
    let values;
  
    if (selectedCard === "inside") {
      values = {
        ...triggers.inside,
        trigger_type: triggers.inside.trigger_type,
        min_probability:
          !triggers.inside.min_probability && triggers.inside.min_probability !== 0
            ? null
            : triggers.inside.min_probability / 100,
        max_distance_km:
          !triggers.inside.max_distance_km && triggers.inside.max_distance_km !== 0
            ? null
            : triggers.inside.max_distance_km / 1000,
      };
    } else if (selectedCard === "outside") {
      values = {
        ...triggers.outside,
        trigger_type: triggers.outside.trigger_type,
        min_probability:
          !triggers.outside.min_probability && triggers.outside.min_probability !== 0
            ? null
            : triggers.outside.min_probability / 100,
        max_distance_km:
          !triggers.outside.max_distance_km && triggers.outside.max_distance_km !== 0
            ? null
            : triggers.outside.max_distance_km / 1000,
      };
    } else {
      values = null;
    }
  
    setSettingsLoading(true);
  
    try {
      await lightningScenarioAPI.create_scenario_predefined_trigger(
        showPopup.scenario_id,
        values
      );
    } catch (error) {
      console.log("There was an error creating a predefined trigger: " + error);
    } finally {
      initializeScenario();
    }
  };
  
  const handleCreateTrigger = () => {
    if (selectedCard === "custom") {
      createCustomTrigger();
    } else {
      createPredefinedTrigger();
    }
  };

  const handleCardClick = (title) => {
    setSelectedCard(title);
  };

  const handleInputChange = (e) => {
    const { name, value, id } = e.target;

    setTriggers((prevState) => ({
      ...prevState,
      [id]: {
        ...prevState[id],
        [name]: value,
      },
    }));
  };

  const initializeScenario = () => {
    setShowPopup({
      state: false,
      type: "",
      scenario_id: null,
      trigger_exists: false,
    });

    fetchScenarios();
    fetchScenariosOverview(windfarm_id);
    setSettingsLoading(false);

    setScenarioTitle("");
    setScenarioDescription("");

    setTriggers((prevTriggers) => ({
      ...prevTriggers,
      inside: {
        trigger_type: "inside-cert",
        min_probability: 9,
        max_distance_km: null,
      },
      outside: {
        trigger_type: "outside-cert",
        min_probability: 9,
        max_distance_km: null,
      },
      custom: {
        is_subsequent: false,
        min_peak_current: null,
        max_peak_current: null,
        min_probability: null,
        max_distance_km: null,
      },
    }));
  };

  useSyncSelectedWindfarm(windfarm_id);

  return (
    <div className="wf-notifications-container">
      <div>
        <p className="disclaimer-container">
          <b>Important:</b> Please note that changing these parameters may
          affect the application’s performance and the notifications being sent.
        </p>
      </div>
      <h2>{windfarm && windfarm.name}</h2>
      {settingsLoading ? (
        <div className="overlay loading">
          <img src={loadingSvg} alt="loading" />
        </div>
      ) : (
        <div>
          <div className="btn-container">
            <WplButton
              onClick={() => setShowPopup({ state: true, type: "scenario" })}
              className="settings-btn"
              value="Create a new notification"
            />
          </div>

          <div className="cards-area">
            {scenarios &&
              scenarios.map((s, i) => {
                if (!s.title) return null;
                return (
                  <div key={i} className="scenario-card">
                    <div className="title-area">
                      <img
                        src={notificationIcon}
                        className="card-icon"
                        alt="icon"
                      />
                      <h4>{s.title}</h4>
                    </div>
                    <div className="content">
                      {s.description && (
                        <div className="description-area">
                          <p className="description-label">Description:</p>
                          <p className="despcription">{s.description}</p>
                        </div>
                      )}

                      {notificationsOverview[windfarm_id] &&
                        notificationsOverview[windfarm_id].map((n, i) =>
                          n.scenario_id === s.scenario_id && n.hasTriggers ? (
                            <div key={i} className="notification">
                              <p>
                                {n.isSubscribed ? "Disable " : "Enable "}
                                notifications
                              </p>
                              <WplSwitch
                                value={n.isSubscribed}
                                onChanged={() =>
                                  updateScenarioStatus(n, windfarm_id)
                                }
                              />
                            </div>
                          ) : null
                        )}

                      <div className="triggers-header">
                        <h4>Triggers</h4>
                        <div className="new-trigger-btn">
                          <img
                            src={newTriggerIcon}
                            className="new-trigger-icon"
                            onClick={() =>
                              setShowPopup({
                                state: true,
                                type: "trigger",
                                scenario_id: s.scenario_id,
                                currentTriggers: s.triggers,
                                trigger_exists: s.trigger_exists,
                              })
                            }
                          ></img>
                        </div>
                      </div>
                      <div className="triggers-area">
                        {s.triggers &&
                          s.triggers.map(
                            (t, i) =>
                              t.id !== null && (
                                <div key={i} className="trigger">
                                  <span className="inline-input">
                                    <p className="trigger-value">
                                      {t.is_subsequent
                                        ? "Subsequent "
                                        : "First "}
                                      stroke
                                    </p>
                                  </span>
                                  <span>
                                    <p className="trigger-key">
                                      Min peak current:
                                    </p>{" "}
                                    <p className="trigger-value">
                                      {t.min_peak_current !== null
                                        ? t.min_peak_current + "kA"
                                        : "N/A"}
                                    </p>
                                  </span>
                                  <span>
                                    <p className="trigger-key">
                                      Max peak current:
                                    </p>{" "}
                                    <p className="trigger-value">
                                      {t.max_peak_current !== null
                                        ? t.max_peak_current + "kA"
                                        : "N/A"}
                                    </p>
                                  </span>
                                  <span>
                                    <p className="trigger-key">
                                      Min probability:
                                    </p>{" "}
                                    <p className="trigger-value">
                                      {t.min_probability !== null
                                        ? parseFloat(
                                            (t.min_probability * 100).toFixed(2)
                                          ) + "%"
                                        : "N/A"}
                                    </p>
                                  </span>
                                  <span>
                                    <p className="trigger-key">Max distance:</p>{" "}
                                    <p className="trigger-value">
                                      {t.max_distance_km !== null &&
                                      t.max_distance_km !== 0
                                        ? Math.trunc(t.max_distance_km * 1000) +
                                          "m"
                                        : "N/A"}
                                    </p>
                                  </span>
                                </div>
                              )
                          )}
                      </div>
                    </div>
                  </div>
                );
              })}
          </div>
          <CenteredPopup showPopup={showPopup.state} closePopup={setShowPopup}>
            {showPopup.state && showPopup.type === "scenario" && (
              <div className="scenario-popup">
                <h4>Create a new notification</h4>
                <div>
                  <div className="scenario-area">
                    <WPLAnimatedInput
                      label="Scenario title"
                      type="text"
                      value={scenarioTitle}
                      onChange={(e) => setScenarioTitle(e.target.value)}
                    />
                  </div>
                  <div className="scenario-area">
                    <WPLAnimatedInput
                      label="Scenario description"
                      type="text"
                      value={scenarioDescription}
                      onChange={(e) => setScenarioDescription(e.target.value)}
                    />
                  </div>
                </div>
                <div className="popup-btn-container">
                  <button
                    onClick={() =>
                      createScenario(scenarioTitle, scenarioDescription)
                    }
                    className="settings-btn"
                  >
                    Create
                  </button>
                </div>
              </div>
            )}
            {showPopup.state && showPopup.type === "trigger" && (
              <div className="scenario-popup">
                <h4>Create a new notification trigger</h4>
                <p className="trigger-description">
                  Define the trigger for lightning strikes associated with this
                  notification.
                </p>
                <div className="triggers-header"></div>
                <div className="new-triggers-area">
                  <div
                    className={`new-trigger-card ${
                      selectedCard === "inside" ? "selected" : ""
                    }`}
                    onClick={() => handleCardClick("inside")}
                  >
                    <h5>Inside certification (IEC)</h5>
                    <span>
                      <p className="trigger-key">Minimum probability</p>
                      <div className="trigger-span">
                        <input
                          className="trigger-input"
                          value={triggers.inside.min_probability}
                          onChange={handleInputChange}
                          type="number"
                          id="inside"
                          name="min_probability"
                          min="0"
                          max="100"
                          step="1"
                        />
                        <p>%</p>
                      </div>
                    </span>
                    <span>
                      <p className="trigger-key">Max distance</p>
                      <div className="trigger-span">
                        <input
                          className="trigger-input"
                          type="number"
                          id="inside"
                          name="max_distance_km"
                          value={triggers.inside.max_distance_km}
                          onChange={handleInputChange}
                          min="0"
                          step="1"
                        />
                        <p>m</p>
                      </div>
                    </span>
                  </div>

                  <div
                    className={`new-trigger-card ${
                      selectedCard === "outside" ? "selected" : ""
                    }`}
                    onClick={() => handleCardClick("outside")}
                  >
                    <h5>Outside certification (IEC)</h5>
                    <span>
                      <p className="trigger-key">Minimum probability</p>
                      <div className="trigger-span">
                        <input
                          className="trigger-input"
                          value={triggers.outside.min_probability}
                          onChange={handleInputChange}
                          type="number"
                          id="outside"
                          name="min_probability"
                          min="0"
                          max="100"
                          step="1"
                        />
                        <p>%</p>
                      </div>
                    </span>
                    <span>
                      <p className="trigger-key">Max distance</p>
                      <div className="trigger-span">
                        <input
                          className="trigger-input"
                          type="number"
                          id="outside"
                          name="max_distance_km"
                          value={triggers.outside.max_distance_km}
                          onChange={handleInputChange}
                          min="0"
                        />
                        <p>m</p>
                      </div>
                    </span>
                  </div>

                  <div
                    className={`new-trigger-card ${
                      selectedCard === "custom" ? "selected" : ""
                    }`}
                    onClick={() => handleCardClick("custom")}
                  >
                    <span className="custom-title-area">
                      <h5>Custom trigger</h5>
                      {selectedCard === "custom" && (
                        <img
                          src={infoIcon}
                          onClick={() =>
                            window.open("/lassie/support", "_blank")
                          }
                          className="info-icon"
                          alt="Open FAQ section"
                          title="Click here to learn more about these parameters"
                        />
                      )}
                    </span>
                    {selectedCard === "custom" ? (
                      <div>
                        <span className="inline-input">
                          <WplTwoButtonInput
                            options={["First stroke", "Subsequent stroke"]}
                            value={
                              triggers.custom.is_subsequent
                                ? "Subsequent stroke"
                                : "First stroke"
                            }
                            onChanged={(selectedOption) => {
                              setTriggers((prevState) => ({
                                ...prevState,
                                custom: {
                                  ...prevState.custom,
                                  is_subsequent:
                                    selectedOption === "Subsequent stroke",
                                },
                              }));
                            }}
                            className="notification-settings"
                          />
                        </span>
                        <span>
                          <p className="trigger-key">Min Probability</p>
                          <div className="trigger-span">
                            <input
                              className="trigger-input"
                              type="number"
                              id="custom"
                              name="min_probability"
                              min="0"
                              max="100"
                              step="1"
                              value={triggers.custom.min_probability}
                              onChange={handleInputChange}
                            />
                            <p>%</p>
                          </div>
                        </span>
                        <span>
                          <p className="trigger-key">Max distance</p>
                          <div className="trigger-span">
                            <input
                              className="trigger-input"
                              type="number"
                              id="custom"
                              name="max_distance_km"
                              value={triggers.custom.max_distance_km}
                              onChange={handleInputChange}
                              min="0"
                            />
                            <p>m</p>
                          </div>
                        </span>
                        <span>
                          <h5>Peak Current</h5>
                          <div className="trigger-span">
                            <input
                              className="trigger-input"
                              type="number"
                              id="custom"
                              name="min_peak_current"
                              value={triggers.custom.min_peak_current}
                              onChange={handleInputChange}
                            />
                            <p className="trigger-key">min kA</p>
                          </div>
                        </span>
                        <br />
                        <span>
                          <div className="trigger-span">
                            <input
                              className="trigger-input"
                              type="number"
                              id="custom"
                              name="max_peak_current"
                              value={triggers.custom.max_peak_current}
                              onChange={handleInputChange}
                            />
                            <p className="trigger-key">max kA</p>
                          </div>
                        </span>
                      </div>
                    ) : (
                      <p className="custom-description">
                        Select this option to customize your parameters for
                        lightning activity.
                      </p>
                    )}
                  </div>
                </div>
                <br />
                {showPopup.trigger_exists ? (
                  <div className="error-area">
                    <p className="error-title">
                      This notification already has active triggers.
                    </p>
                    <p className="error-message">
                      Are you sure you want to create it anyway?
                    </p>
                  </div>
                ) : null}
                <div className="popup-btn-container">
                  <button
                    onClick={() => handleCreateTrigger()}
                    className="settings-btn"
                  >
                    Create
                  </button>
                </div>
              </div>
            )}
          </CenteredPopup>
        </div>
      )}
    </div>
  );
}
