import { http } from "../api";

const easyUnpack = ({ data }) => data;
export const LassieApi = {
    list_notifications: {
        f: function list_notifications(windfarm_id) {
            let url = '/lassie/windfarm/$windfarm_id/notifications';
            let parameterNames = ["$windfarm_id"];
            [windfarm_id].forEach((parameterValue, index) => {
                url = url.replace(parameterNames[index], parameterValue)
            })
            return http.get(url, { params: {} }).then(easyUnpack)
        }
    },
    get_risk_timeline: {
        f: function get_risk_timeline(windfarm_id) {
            let url = '/lassie/windfarm/$windfarm_id/risk-timeline';
            let parameterNames = ["$windfarm_id"];
            [windfarm_id].forEach((parameterValue, index) => {
                url = url.replace(parameterNames[index], parameterValue)
            })
            return http.get(url, { params: {} }).then(easyUnpack)
        }
    },
    list_windfarm_strikes_today: {
        f: function list_windfarm_strikes_today(windfarm_id) {
            let url = '/lassie/windfarm/$windfarm_id';
            let parameterNames = ["$windfarm_id"];
            [windfarm_id].forEach((parameterValue, index) => {
                url = url.replace(parameterNames[index], parameterValue)
            })
            return http.get(url, { params: {} }).then(easyUnpack)
        }
    },
    daily_status: {
        f: function daily_status(year, month, day, distance_limit) {
            let url = '/lassie/status/$year/$month/$day';
            let parameterNames = ["$year", "$month", "$day"];
            [year, month, day].forEach((parameterValue, index) => {
                url = url.replace(parameterNames[index], parameterValue)
            })
            return http.get(url, { params: { distance_limit } }).then(easyUnpack)
        }
    },
    list_subscribers: {
        f: function list_subscribers() {
            let url = '/lassie/subscribers';
            return http.get(url, { params: {} }).then(easyUnpack)
        }
    },
    list_lightning_subscribed_turbines_by_windfarm_id: {
        f: function list_lightning_subscribed_turbines_by_windfarm_id(windfarm_id) {
            let url = '/lassie/windfarm/$windfarm_id/turbines';
            let parameterNames = ["$windfarm_id"];
            [windfarm_id].forEach((parameterValue, index) => {
                url = url.replace(parameterNames[index], parameterValue)
            })
            return http.get(url, { params: {} }).then(easyUnpack)
        }
    },
    list_lightning_overview: {
        f: function list_lightning_overview() {
            let url = '/lassie';
            return http.get(url, { params: {} }).then(easyUnpack)
        }
    },
    get_severity_probabilities_since_date: {
        f: function get_severity_probabilities_since_date(turbine_id) {
            let url = '/lassie/turbine/$turbine_id';
            let parameterNames = ["$turbine_id"];
            [turbine_id].forEach((parameterValue, index) => {
                url = url.replace(parameterNames[index], parameterValue)
            })
            return http.get(url, { params: {} }).then(easyUnpack)
        }
    },
    list_service_agreement_triggers: {
        f: function list_service_agreement_triggers(turbine_id) {
            let url = '/lassie/turbine/$turbine_id/service-agreement-triggers';
            let parameterNames = ["$turbine_id"];
            [turbine_id].forEach((parameterValue, index) => {
                url = url.replace(parameterNames[index], parameterValue)
            })
            return http.get(url, { params: {} }).then(easyUnpack)
        }
    },
    list_turbines: {
        f: function list_turbines({ windfarm_id = null, turbine_id = null }) {
            let url = '/lassie/turbines';
            return http.get(url, { params: { windfarm_id, turbine_id } }).then(easyUnpack)
        }
    },
    list_turbines_with_historic_lightning_activity: {
        f: function list_turbines_with_historic_lightning_activity(from_timestamp, to_timestamp, windfarm_id) {
            let url = '/lassie/turbines/historic';
            return http.get(url, { params: { from_timestamp, to_timestamp, windfarm_id } }).then(easyUnpack)
        }
    },
    historic_lightning_activity: {
        f: function historic_lightning_activity(from_timestamp, to_timestamp, windfarm_id, { turbine_id = null }) {
            let url = '/lassie/historic-lightning-activity';
            return http.get(url, { params: { from_timestamp, to_timestamp, windfarm_id, turbine_id } }).then(easyUnpack)
        }
    },
    get_historic_user_action: {
        f: function get_historic_user_action(from_timestamp, to_timestamp, { windfarm_id = null, turbine_id = null }) {
            let url = '/lassie/historic-user-action';
            return http.get(url, { params: { from_timestamp, to_timestamp, windfarm_id, turbine_id } }).then(easyUnpack)
        }
    },
    insert_user_action: {
        f: function insert_user_action(turbine_ids, action, {
            target_date = null,
            user_comment = null,
            file_names = null,
            file_datas = null
        }) {
            let url = '/lassie/user-action';
            return http.post(url, {
                turbine_ids,
                action,
                target_date,
                user_comment,
                file_names,
                file_datas
            }).then(easyUnpack)
        }
    },
    windfarm_statistics: {
        f: function windfarm_statistics({ windfarm_id = null, turbine_id = null }) {
            let url = '/lassie/statistics';
            return http.get(url, { params: { windfarm_id, turbine_id } }).then(easyUnpack)
        }
    },
    action_history: {
        f: function action_history({ turbine_id = null }) {
            let url = '/lassie/action-history';
            return http.get(url, { params: { turbine_id } }).then(easyUnpack)
        }
    },
    get_latest_turbine_action: {
        f: function get_latest_turbine_action(turbine_id) {
            let url = '/lassie/turbine/$turbine_id/latest-action';
            let parameterNames = ["$turbine_id"];
            [turbine_id].forEach((parameterValue, index) => {
                url = url.replace(parameterNames[index], parameterValue)
            })
            return http.get(url, { params: {} }).then(easyUnpack)
        }
    },
    list_windfarms_by_user_id: {
        f: function list_windfarms_by_user_id() {
            let url = '/lassie/windfarm';
            return http.get(url, { params: {} }).then(easyUnpack)
        }
    },
    turbine_activity_history: {
        f: function turbine_activity_history(turbine_id) {
            let url = '/lassie/turbine/$turbine_id/activity';
            let parameterNames = ["$turbine_id"];
            [turbine_id].forEach((parameterValue, index) => {
                url = url.replace(parameterNames[index], parameterValue)
            })
            return http.get(url, { params: {} }).then(easyUnpack)
        }
    },
    delete_user_action: {
        f: function delete_user_action(user_action_id) {
            let url = '/lassie/user-action/$user_action_id';
            let parameterNames = ["$user_action_id"];
            [user_action_id].forEach((parameterValue, index) => {
                url = url.replace(parameterNames[index], parameterValue)
            })
            return http.delete(url, { data: {} }).then(easyUnpack)
        }
    },
    list_windfarms: {
        f: function list_windfarms() {
            let url = '/lassie/windfarms';
            return http.get(url, { params: {} }).then(easyUnpack)
        }
    },
};
