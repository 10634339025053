import React, { useState, useEffect } from "react";
import "./wpl-animated-input.css";

export default function WPLAnimatedInput({
  label,
  type = "text",
  value,
  onChange,
  placeholder = " ",
  inputClassName = "",
  containerClassName = "",
  labelClassName = "",
  ...props
}) {
  const [isActive, setIsActive] = useState(value !== "");

  const handleInputChange = (e) => {
    onChange(e);
    setIsActive(e.target.value !== "");
  };

  return (
    <form
      className={`wpl-input-container ${containerClassName}`}
      autoComplete="off"
    >
      <input
        name={`input-${Math.random().toString(36).substr(2, 9)}`}
        autoComplete="new-password"
        className={`wpl-account-input ${inputClassName}`}
        type={type}
        value={value}
        onChange={handleInputChange}
        onFocus={() => setIsActive(true)}
        onBlur={() => setIsActive(value !== "")}
        placeholder={placeholder}
        {...props}
      />
      <label
        className={
          isActive
            ? `wpl-label Active ${labelClassName}`
            : `wpl-label ${labelClassName}`
        }
      >
        {label}
      </label>
    </form>
  );
}
