import React, { useState } from "react";
import "./wpl-select-input.css";

export default function WplSelectInput({
  label,
  value = "",
  onChange,
  options,
  placeholder = " ",
  inputClassName = "",
  containerClassName = "",
  labelClassName = "",
  ...props
}) {
  const [isFocused, setIsFocused] = useState(false);

  const handleSelectChange = (e) => {
    onChange && onChange(e.target.value);
    setIsFocused(e.target.value !== "");
  };

  return (
    <div
      className={`select-input-container ${containerClassName} ${
        isFocused ? "focused" : ""
      }`}
    >
      <select
        className={`select-input ${inputClassName}`}
        value={value}
        onChange={handleSelectChange}
        onFocus={() => setIsFocused(true)}
        onBlur={() => setIsFocused(value !== "")}
        {...props}
      >
        <option value="" disabled hidden>
          {placeholder}
        </option>
        {options.length > 0 &&
          options.map((option, index) => (
            <option key={index} value={option}>
              {option}
            </option>
          ))}
      </select>
      <label
        className={
          isFocused || value
            ? `select-label Active ${labelClassName}`
            : `select-label ${labelClassName}`
        }
      >
        {label}
      </label>
    </div>
  );
}
