import { http } from "../api";

const easyUnpack = ({ data }) => data;
export const LassieScenarioApi = {
  create_lassie_scenario: {
    f: function create_lassie_scenario(
      windfarm_id,
      title,
      { immediate_action = null, description = null }
    ) {
      let url = "/lassie/scenario";
      return http
        .post(url, { windfarm_id, title, immediate_action, description })
        .then(easyUnpack);
    },
  },
  list_scenarios: {
    f: function list_scenarios(windfarm_id) {
      let url = "/lassie/scenario/windfarm/$windfarm_id";
      let parameterNames = ["$windfarm_id"];
      [windfarm_id].forEach((parameterValue, index) => {
        url = url.replace(parameterNames[index], parameterValue);
      });
      return http.get(url, { params: {} }).then(easyUnpack);
    },
  },
  scenarios_overview: {
    f: function scenarios_overview() {
      let url = "/lassie/scenario/windfarms/overview";
      return http.get(url, { params: {} }).then(easyUnpack);
    },
  },
  base_view: {
    f: function base_view(frequency, scenario_id) {
      let url = "/lassie/scenario/$scenario_id/subscribe/$frequency";
      let parameterNames = ["$frequency", "$scenario_id"];
      [frequency, scenario_id].forEach((parameterValue, index) => {
        url = url.replace(parameterNames[index], parameterValue);
      });
      return http.post(url, {}).then(easyUnpack);
    },
  },
  create_scenario_user_subscription: {
    f: function create_scenario_user_subscription(
      frequency,
      scenario_id,
      target_user_id
    ) {
      let url =
        "/lassie/scenario/$scenario_id/subscribe/user/$target_user_id/frequency/$frequency";
      let parameterNames = ["$frequency", "$scenario_id", "$target_user_id"];
      [frequency, scenario_id, target_user_id].forEach(
        (parameterValue, index) => {
          url = url.replace(parameterNames[index], parameterValue);
        }
      );
      return http.post(url, {}).then(easyUnpack);
    },
  },
  delete_user_subscription: {
    f: function delete_user_subscription(frequency, scenario_id) {
      let url = "/lassie/scenario/$scenario_id/subscribe/$frequency";
      let parameterNames = ["$frequency", "$scenario_id"];
      [frequency, scenario_id].forEach((parameterValue, index) => {
        url = url.replace(parameterNames[index], parameterValue);
      });
      return http.delete(url, { data: {} }).then(easyUnpack);
    },
  },
  delete_scenario_user_subscription: {
    f: function delete_scenario_user_subscription(
      frequency,
      scenario_id,
      target_user_id
    ) {
      let url =
        "/lassie/scenario/$scenario_id/subscribe/user/$target_user_id/frequency/$frequency";
      let parameterNames = ["$frequency", "$scenario_id", "$target_user_id"];
      [frequency, scenario_id, target_user_id].forEach(
        (parameterValue, index) => {
          url = url.replace(parameterNames[index], parameterValue);
        }
      );
      return http.delete(url, { data: {} }).then(easyUnpack);
    },
  },
  create_lassie_trigger: {
    f: function create_lassie_trigger(
      scenario_id,
      {
        is_subsequent = null,
        min_peak_current = null,
        max_peak_current = null,
        min_probability = null,
        min_distance_km = null,
        max_distance_km = null,
        max_uncertainty_km = null,
      }
    ) {
      let url = "/lassie/scenario/$scenario_id/trigger";
      let parameterNames = ["$scenario_id"];
      [scenario_id].forEach((parameterValue, index) => {
        url = url.replace(parameterNames[index], parameterValue);
      });
      return http
        .post(url, {
          is_subsequent,
          min_peak_current,
          max_peak_current,
          min_probability,
          min_distance_km,
          max_distance_km,
          max_uncertainty_km,
        })
        .then(easyUnpack);
    },
  },
  create_lassie_predefined_trigger: {
    f: function create_lassie_predefined_trigger(
      scenario_id,
      { min_probability = null, max_distance_km = null, trigger_type = null }
    ) {
      let url = "/lassie/scenario/$scenario_id/predefined-trigger";
      let parameterNames = ["$scenario_id"];
      [scenario_id].forEach((parameterValue, index) => {
        url = url.replace(parameterNames[index], parameterValue);
      });
      return http
        .post(url, { min_probability, max_distance_km, trigger_type })
        .then(easyUnpack);
    },
  },
};
