import React, { useCallback } from "react";
import "./wpl-switch.css";
import infoIcon from "../../assets/img/icon-info.png";

export default function WplSwitch({
  title,
  value,
  onChanged,
  style,
  className,
  disabled,
  tooltip,
  labelClassname,
  ...rest
}) {
  const proxyOnChanged = useCallback(
    (e) => {
      const newValue = e.target.checked;
      onChanged(newValue);
    },
    [onChanged]
  );

  return (
    <div className={`wpl-switch ${className ? className : ""}`} style={style}>
      <label className={labelClassname ? labelClassname : "title-label"}>
        <span>{title}</span>
        {tooltip && tooltip.trim() !== "" && (
          <div className="tooltip">
            <img src={infoIcon} alt="info icon" className="tooltip-icon" />
            <span className="tooltiptext">{tooltip}</span>
          </div>
        )}
      </label>
      <label className="switch">
        <input
          type="checkbox"
          checked={value}
          disabled={disabled}
          onChange={proxyOnChanged}
          {...rest}
        />
        <span className="slider round"></span>
      </label>
    </div>
  );
}
