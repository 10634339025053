import React, { useMemo } from "react";
import { toPrettyDateStr, toPrettyTimeSince } from "../../prettyDate";
import { probabilityToString } from "./probabilityToString";
import highRiskIcon from "../../assets/lassie/at-risk-high.png";
import mediumRiskIcon from "../../assets/lassie/at-risk-medium.png";
import lowRiskIcon from "../../assets/lassie/at-risk-low.png";
import "./lightning-strike-list.css";

export default function LightningStrikeList({ lightningStrikes, hovering }) {
  const dateGroups = useMemo(() => {
    if (!lightningStrikes) return {};
    const groups = {};
    const k = [...lightningStrikes];
    k.sort((a, b) => new Date(b.timestamp) - new Date(a.timestamp)).forEach(
      (strike) => {
        const s = toPrettyDateStr(new Date(strike.timestamp), true);
        let [d, h] = s.split(" ");
        let timeSince = toPrettyTimeSince(new Date(strike.timestamp));

        if (d !== timeSince) {
          d = `${d} (${timeSince})`;
        } else {
          d = timeSince;
        }

        if (!groups[d]) {
          groups[d] = [];
        }
        groups[d].push({ ...strike, ts: h });
      }
    );
    return groups;
  }, [lightningStrikes]);

  const getIconByProbability = (prob) => {
    if (prob === undefined) return null;
    if (prob < 0.09) return lowRiskIcon;
    if (prob < 0.37) return mediumRiskIcon;
    return highRiskIcon;
  };

  return (
    <div className="lightning-strike-list">
      {Object.keys(dateGroups).map((dateTitle, idx) => (
        <div key={dateTitle}>
          <div className="title-wrapper">
            <h4 className="title">{dateTitle}</h4>
          </div>

          <table className="lightning-strike-list">
            <thead>
              <tr>
                <th>Timestamp</th>
                <th>Attachment Risk</th>
                <th>Distance</th>
                <th>Peak Current</th>
                <th>Outside IEC</th>
              </tr>
            </thead>
            <tbody>
              {dateGroups[dateTitle].map((ls) => (
                <tr key={ls.id}>
                  <td>
                    {ls.ts}.
                    {Math.round(ls.nanosecond / 1e6)
                      .toString()
                      .padStart(3, "0")}
                  </td>
                  <td className="attachment-risk-wrapper">
                    <div className="attachment-risk">
                      {probabilityToString(ls.probability_of_hit)} attachment
                      risk
                      <img
                        src={getIconByProbability(ls.probability_of_hit)}
                        className="risk-icon"
                      />
                    </div>
                  </td>
                  <td>{Math.round(ls.distance_km * 100) * 10} m</td>
                  <td>{Math.round(ls.peak_current * 100) / 100} kA</td>
                  <td>{ls.outside_certification ? "✔" : ""}</td>
                </tr>
              ))}
            </tbody>
          </table>
        </div>
      ))}
    </div>
  );
}
